<template>
  <div class="public-body">
    <div class="public-body__block  public-body__block--decor">
      <img src="@/assets/img/mkp-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__icon">
        <img src="@/assets/img/public/error.svg" alt="logo" />
      </div>
      <div class="public-body__headline">
        К сожалению, заявку не удалось отправить
      </div>
      <div class="public-body__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin amet
        lectus pellentesque vestibulum, turpis pellentesque risus imperdiet.
        Aliquam aliquam nam consectetur ut purus. Luctus faucibus ut velit amet
        leo eget varius. Phasellus ullamcorper turpis pharetra, facilisis
        dictumst.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error'
};
</script>
